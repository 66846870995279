import React, { lazy } from "react";
import NavComponent from "./navbar.tsx";
// import Button from "react-bootstrap/Button";
import Button from "@mui/material/Button";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useNavigate } from "react-router-dom";
import StickyFooter from "./components/bottom.tsx";
import { useContext } from "react";
import { UserContext } from "./App";
import emailjs from "emailjs-com";
import CallIcon from "@mui/icons-material/Call";
import CheckIcon from "@mui/icons-material/Check";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import Box from "@mui/material/Box";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import VerifiedIcon from "@mui/icons-material/Verified";

import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Margin } from "@mui/icons-material";

// console.log(State.getAllStates())

function Payment() {
  const navigate = useNavigate();
  const { user, setUser } = useContext(UserContext);
  const handleClick = () => {
    console.log("Confirming payment");
    console.log(user);
    sendEmail();
    navigate("/final");
  };
  const sendEmail = () => {
    // return;
    // e.preventDefault();    //This is important, i'm not sure why, but the email won't send without it

    emailjs
      .send("service_nnvyyn4", "template_t81hk2j", user, "U-mN1d2ubkdUSV2Tz")
      .then(
        (result) => {
          // window.location.reload()  //This is if you still want the page to reload (since e.preventDefault() cancelled that behavior)
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div
      style={{
        height: "100%",
        backgroundColor: "#aaedff",
        // backgroundImage:
        //   "url('https://images.unsplash.com/photo-1513002749550-c59d786b8e6c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2848&q=80')",
      }}
    >
      <NavComponent />

      <Container>
        <br />
        <br />
        <br />

        <Typography>
              {" "}
              <h2 style={{ fontSize: "23px" }}>
                {" "}
       Choose Payment Method
              </h2>
            </Typography>
            <br/>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>
              {" "}
              <h2 style={{ fontSize: "20px" }}>
                {" "}
                <b> Pay Later </b>{" "}
              </h2>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <li
                class="list-group-item"
                style={{ }}
              >
                <h3 style={{ fontSize: "20px" }}> </h3>
                <Button
                  // fullWidth
                  variant="contained"
                  onClick={handleClick}
                  
                  sx={{ mb: 1, padding: 2 , alignSelf:"center",width: "70%"}}
                  // onClick={handleClick}
                  // variant="outline-success"
                  // size="lg"
                  // type="submit"
                  // style={{ padding: "3%" }}
                >
                 Click to continue
                </Button>
                {/* <br /> <QuestionAnswerIcon /> {} */}
                {/* <div dangerouslySetInnerHTML={{ __html: desc }} /> */}
              </li>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>
              {" "}
              <h2 style={{ fontSize: "20px" }}>
                {" "}
                <b> Pay via UPI </b>{" "}
              </h2>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <li
                class="list-group-item"
                style={{  width:"60%" ,alignSelf:"center",}}
                // sx={{ mb: 1, padding: 2 , alignSelf:"center",width: "50%"}}
              >
                <h3 style={{ fontSize: "20px" }}> </h3>
                <Card.Img
                  variant="top"
                  src={
                    process.env.PUBLIC_URL +
                    "/QRSCANforDummy-ticket-for-visa.png"
                  }
                />
                <Card.Title style={{ fontFamily: "", marginLeft: "10%" }}>
                  {" "}
                  <QrCodeScannerIcon /> Scan and Pay{" "}
                  <span
                    style={{
                      textDecoration: "line-through",
                      // fontFamily: "sans-serif",
                    }}
                  >
                    {/* $8{"/-"} */}
                  </span>{" "}
                  <b> $1 only </b>
                </Card.Title>
                {/* <br /> <QuestionAnswerIcon /> {} */}
                {/* <div dangerouslySetInnerHTML={{ __html: desc }} /> */}
              </li>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>
              {" "}
              <h2 style={{ fontSize: "20px" }}>
                {" "}
                <b>
                  {" "}
                  Pay with Card <CreditCardIcon fontSize="large" />{" "}
                </b>{" "}
              </h2>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <li
                class="list-group-item"
                // style={{ backgroundColor: "#eafdff" }}
              >
                <h3 style={{ fontSize: "20px" }}> </h3>
                <span
                  style={{
                    fontSize: "25px",
                    fontFamily: "sans-serif",
                    marginRight: "2%",
                  }}
                >
                  {" "}
                  {/* Pay via Card  */}
                </span>
                <stripe-buy-button
                  buy-button-id="buy_btn_1Nzgi0SHnzSA41KhmXNEyrku"
                  publishable-key="pk_live_51NzgGzSHnzSA41Kh2PXhupdzVOICRJ81gqkzND09OJTn80a5PGO7rSy4rx1WNiu3fcbQn0ZCN312GcNFKCz62hJo00X6hlJt1R"
                ></stripe-buy-button>
                {/* <br /> <QuestionAnswerIcon /> {} */}
                {/* <div dangerouslySetInnerHTML={{ __html: desc }} /> */}
              </li>
            </Typography>
          </AccordionDetails>
        </Accordion>

        {/* <Row
          className="justify-content-md-center"
          mx={8}
          style={
            {
              width: "98%",

              // backgroundColor: "#fadfd2",
              // backgroundImage: "url(`https://static.toiimg.com/photo/70739792.cms`)",
              // width: "100%",
              // height: "100%",
              // backgroundImage: "url(`https://i.insider.com/5e8f70a8c023205e4245d983?width=700`)",
            }
          }
        > */}
        <Box
          sx={{
            marginTop: 3,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "98%",
            // backgroundColor: "#eafdff",
          }}
        >
          
            {/* <Card.Body> */}
              <div
                // style={{width:"30%"}}
                style={{ fontFamily: "" }}
              ></div>
              <br/>
              <Button
                fullWidth
                variant="contained"
                onClick={handleClick}
                sx={{ mb: 1, padding: 2 , width:"50%" }}
                // onClick={handleClick}
                // variant="outline-success"
                // size="lg"
                // type="submit"
                // style={{ padding: "3%" }}
              >
                Proceed
              </Button>

              <Box style={{ fontSize: "18px"  ,margin: "5%",   width:"70%",  padding :" 3%", backgroundColor: "#eafdff",}}>
                Steps :
                <br />
                <MonetizationOnIcon /> Complete Payment on the above QR/Link{" "}
                <br />
                <VerifiedIcon /> Once paid click proceed.
                <br />
                <CallIcon /> If any issues contact +91-7071341203
              </Box>
            {/* </Card.Body> */}
            <br />
          {/* </Card> */}
        </Box>
        {/* </Row> */}
        {/* <Row>
        <img 
 style={{
    width: "100%",
    // margin: "3%"
  }}
src={process.env.PUBLIC_URL + "/what.png"} alt="" />
        </Row> */}
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </Container>
      <StickyFooter />
    </div>
  );
}

export default Payment;

{
  /* <Form.Group className="mb-6" controlId="formBasicEmail">
<Form.Label style={{ marginTop: "5%" }}>
  {" "}
  <h5> Last Name </h5>{" "}
</Form.Label>
{/* <Form.Control
  type="tel"
  name="lastName"
  placeholder="Enter Last Name"
  onChange={handleChange}
/> */
}
//   <PhoneInput
//     placeholder="Enter phone number"
//     // name="lastName"
//     country="US"
//     value={value}
//     onChange={setValue}
//     />

// </Form.Group> */}
