import Form from "react-bootstrap/Form";
import React from "react";
// import { SelectPicker } from "rsuite";
import { useContext, useState } from "react";
import { UserContext } from "../App";
import AirplaneTicketIcon from "@mui/icons-material/AirplaneTicket";
// console.log(City.getAllCities([]));
// import { useState } from 'react';
// import PhoneInput from 'react-phone-number-input/input'
// import { COLOR } from "rsuite/esm/utils/constants";

function BasicExampleForm() {
  const data = [
    "Eugenia",
    "Bryan",
    "Linda",
    "Nancy",
    "Lloyd",
    "Alice",
    "Julia",
    "Albert",
  ].map((item) => ({ label: item, value: item }));
  const { user, setUser } = useContext(UserContext);
  const [value, setValue] = useState();
  // const [validated, setValidated] = useState(false);

  const handleChange = (event) => {
    const value = event.target.value;
    // console.log('--');
    // console.log(event.target.value);
    // console.log(event.target.name);

    setUser((previousState) => {
      return { ...previousState, [event.target.name]: value };
    });
    // setUser({ firstName:  event.target.value })
  };

  return (
    <Form
      style={{
        width: "96%",
        minWidth: "13rem",
        maxWidth: "50rem",
        margin: "2%",
        padding: "2%",
        color: "black",
        backgroundColor: "white",
        opacity: "0.9",
        backgroundColor: "#eafdff",
        alignSelf: "center",
      }}

      // style={{
      //   backgroundColor: "#eafdff",
      //   opacity: "0.8",
      //     // fontFamily: "Poppins",
      //   fontSize: "20px"
      // }}
    >
      <Form.Group
        style={
          {
            // width: "50%",
            // minWidth: "16rem",
            // marginTop: "2%",
            // padding: "2%",
            // color: "black",
            // backgroundColor: "white",
            // alignSelf: "center",
            // backgroundColor: "#eafdff",
            // opacity: "0.9",
          }
        }
      >
        <h4 style={{alignSelf:"center"}}>
          {" "}
          <AirplaneTicketIcon /> Book Now!{" "}
        </h4>
        <br />
        <Form.Label style={{ marginTop: "1%" }}>
          {" "}
          <h5  style={{ marginTop: "1%" , fontSize: "18px" }}> Title </h5>{" "}
        </Form.Label>
        <Form.Select
          aria-label="Default select example"
          name="title"
          onChange={handleChange}
          required
        >
          <option>Choose</option>
          <option value="Mr">Mr</option>
          <option value="Mrs">Mrs</option>
          <option value="Miss">Miss</option>
        </Form.Select>
      </Form.Group>

      <Form.Group className="mb-6" controlId="formBasicEmail">
        <Form.Label style={{ marginTop: "5%" }}>
          {" "}
          <h5  style={{ marginTop: "1%" , fontSize: "18px" }}> Full Name </h5>{" "}
        </Form.Label>
        <Form.Control
          type="text"
          name="firstName"
          placeholder="Enter Full Name"
          onChange={handleChange}
          required
        />
      </Form.Group>

      {/* <Form.Group></Form.Group> */}
      <Form.Group className="mb-6" controlId="formBasicEmail">
        <Form.Label style={{ marginTop: "5%" }}>
          {" "}
          <h5  style={{ marginTop: "1%" , fontSize: "18px" }}>Email address </h5>{" "}
        </Form.Label>
        <Form.Control
          type="email"
          name="email"
          placeholder="Enter email"
          onChange={handleChange}
        />
      </Form.Group>
      {/* <SelectPicker data={data} style={{ width: "80%" }} /> */}

      <Form.Group>
        <Form.Label style={{ marginTop: "5%" }}>
          {" "}
          <h5  style={{ marginTop: "1%" , fontSize: "18px" }}> Departure City </h5>{" "}
        </Form.Label>
        <Form.Control
          type="text"
          name="fromCity"
          placeholder="Enter From City"
          onChange={handleChange}
        />
        {/* <Form.Select aria-label="Default select example">
          <option>Choose</option>
          <option value="1">Bangalore</option>
          <option value="2">Delhi</option>
          <option value="3">Kolkata</option>
          <option value="4">Hyderabad</option>
          <option value="5">Dubai</option>
          <option value="6">Istanbul</option>
          <option value="7">London</option>
          <option value="8">New York</option>
          <option value="9">Madrid</option>
          <option value="10">Mumbai</option>
          <option value="12">Doha</option>
          <option value="13">Tokyo</option>
          <option value="14">Shanghai</option>
          <option value="15">Los Angeles</option>
          <option value="16">Paris</option>
          <option value="17">Beijing</option>
          <option value="18">Seoul</option>
          <option value="19">Moscow</option>
          <option value="20">Atlanta</option>
          <option value="21">Chicago</option>
          <option value="22">Bangkok</option>
          <option value="23"> Hong Kong</option>
          <option value="24">São Paulo</option>
          <option value="25">Jakarta</option>
          <option value="26">Frankfurt</option>
          <option value="27">Amsterdam</option>
          <option value="28">Kuala Lumpur</option>
          <option value="29">Singapore</option>
          <option value="30">Guangzhou</option>
          <option value="31">Milano</option>
        </Form.Select> */}
      </Form.Group>

      <Form.Group>
        <Form.Label style={{ marginTop: "5%" }}>
          {" "}
          <h5  style={{ marginTop: "1%" , fontSize: "18px" }}> Arrival City </h5>{" "}
        </Form.Label>
        <Form.Control
          type="text"
          name="destCity"
          placeholder="Enter Destination City"
          onChange={handleChange}
        />

        {/* <Form.Select aria-label="Default select example">
          <option>Choose</option>
          <option value="1">Bangalore</option>
          <option value="2">Delhi</option>
          <option value="3">Kolkata</option>
          <option value="4">Hyderabad</option>
          <option value="5">Dubai</option>
          <option value="6">Istanbul</option>
          <option value="7">London</option>
          <option value="8">New York</option>
          <option value="9">Madrid</option>
          <option value="10">Mumbai</option>
          <option value="12">Doha</option>
          <option value="13">Tokyo</option>
          <option value="14">Shanghai</option>
          <option value="15">Los Angeles</option>
          <option value="16">Paris</option>
          <option value="17">Beijing</option>
          <option value="18">Seoul</option>
          <option value="19">Moscow</option>
          <option value="20">Atlanta</option>
          <option value="21">Chicago</option>
          <option value="22">Bangkok</option>
          <option value="23"> Hong Kong</option>
          <option value="24">São Paulo</option>
          <option value="25">Jakarta</option>
          <option value="26">Frankfurt</option>
          <option value="27">Amsterdam</option>
          <option value="28">Kuala Lumpur</option>
          <option value="29">Singapore</option>
          <option value="30">Guangzhou</option>
          <option value="31">Milano</option>
        </Form.Select> */}
      </Form.Group>


      <Form.Group className="mb-3" controlId="formBasicEmail">
      <Form.Label style={{ marginTop: "4%" , fontSize: "20px" }}>
          {" "}
          <h5  style={{ marginTop: "4%" , fontSize: "18px" }}> Whatsapp Number </h5>{" "}
        </Form.Label>
        <Form.Control
          type="number"
          placeholder="Enter whatsapp number"
          name="mobileNumber"
          onChange={handleChange}
        />
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label style={{ marginTop: "3%" , fontSize: "22px" }}>
          {" "}
          <h5  style={{ marginTop: "1%" , fontSize: "18px" }}>  Travel date </h5>{" "}
        </Form.Label>
        <Form.Control
          type="date"
          placeholder="Enter date"
          name="date"
          onChange={handleChange}
        />
      </Form.Group>

      {/* Contact number */}

      {/* <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Label> <h4> Password </h4> </Form.Label>
        <Form.Control type="password" placeholder="Password" />
      </Form.Group> */}

      {/* <Form.Group className="mb-3" controlId="formBasicCheckbox">
        <Form.Check type="checkbox" label="Check me out" />
      </Form.Group> */}
    </Form>
  );
}

export default BasicExampleForm;
