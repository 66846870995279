import React from "react";
import Faq from "../components/faq.tsx";
import NavComponent from "../navbar.tsx";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";

function DummyTicketForVisa() {
  return (
    <div
      style={{
        height: "100%",

      //  backgroundImage:
        //  "url('https://images.unsplash.com/photo-1513002749550-c59d786b8e6c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2848&q=80')",
      }}
    >
      {/* <NavComponent /> */}

      <ul
        class="list-group list-group-flush"
        style={{
          // backgroundColor: "#eafdff",
          backgroundColor:"#aaedff",
          opacity: "0.9",
          padding: "3%",
          // lineBreak:""
          // fontFamily: "Helvetica"
          // margin: "1%",
          fontSize: "20px",
        }}
      >
        {/* <b> Sample:  </b> */}
        <img
          style={{ width: "30%", alignSelf: "center" }}
          src={process.env.PUBLIC_URL + "/dummy-ticket-for-visa-application-sample.jpg"}
        ></img>
        <br />

        <h1 style={{  fontSize: "33px",}}> What is dummy ticket for visa ? </h1>
        <br />

        <li class="list-group-item" style={{ backgroundColor: "#eafdff" ,  padding: "5%", }}>
          {/* <br /> <QuestionAnswerIcon /> */}
          A "dummy ticket" or "flight itinerary" is not an official travel
          document, but rather a simulated or provisional flight booking that is
          often used when applying for a visa, especially in cases where you
          need to show proof of travel plans to the embassy or consulate as part
          of your visa application process. It is not a real ticket and cannot
          be used for actual travel. Instead, it serves as a placeholder to
          demonstrate your intention to leave the destination country and return
          to your home country or another destination after your visit.
          <br />
          <br />
          Here are some key points to understand about dummy tickets:
          <br />
          <b>- Visa Application Requirement:</b>
          Some countries, particularly those with stricter visa application
          processes, may require applicants to provide proof of their travel
          plans, including flight bookings, before a visa is granted.
          <br />
          <b>- Not for Actual Travel:</b>
          A dummy ticket is not a valid airline ticket, and it cannot be used to
          board a flight or for any other travel purposes. It's typically
          canceled or invalid for travel.
          <br />
          <b>- Cancellation:</b>
          Dummy tickets are often temporary reservations created by travel
          agencies or online booking platforms. They are typically canceled
          within a specified period (e.g., 24-72 hours) automatically if not
          converted into real bookings.
          <br />
          <b>- Flexibility:</b>
          They provide applicants with flexibility as they can be canceled
          without cost if the visa application is denied, or if travel plans
          change.
          <br />
          <b>- Booking Confirmation:</b>
          Some embassies may accept a confirmed flight reservation or a printout
          of an itinerary as proof of travel plans.
          <br />
          <br />
          It's important to check the specific requirements of the embassy or
          consulate where you are applying for a visa. In some cases, they may
          require a confirmed flight reservation or a booked ticket, while in
          other cases, a simple printout of an itinerary might suffice.
          Additionally, some visa applicants opt to use the services of a travel
          agency to provide them with a valid flight itinerary that can be
          canceled if the visa is denied or modified if travel plans change.
          Always verify the specific requirements with the relevant authorities
          to ensure a smooth visa application process.
        </li>
      </ul>
    </div>
  );
}

export default DummyTicketForVisa;
