import Card from "react-bootstrap/Card";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import VerifiedIcon from "@mui/icons-material/Verified";
import AirplaneTicketIcon from "@mui/icons-material/AirplaneTicket";
import ApprovalIcon from "@mui/icons-material/Approval";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";

function HomeForm() {
  return (
    <Form style={{ width: "100%" }}>
      <Form.Group>
        <Container style={{ width: "100%", minWidth: "18rem", color: "black" }}>
          <br />
          <br />
          <br />

          <Row className="justify-content-md-center" mx={8}>
            <Col>
              <Card
                style={{
                  backgroundColor: "#eafdff",
                  opacity: "0.9",
                  // fontFamily: "Poppins",
                  fontSize: "20px",
                }}
              >
                <Card.Body>
                  <h1 style={{ fontSize: "20px" }}>
                    <img
                      src={process.env.PUBLIC_URL + "/dummyticketforvisa.png"}
                      width={"5%"}
                      height={"5%"}
                      min-height={"10rem"}
                      class="image_part"
                      alt="logo2"
                    ></img>
                    {"  "}Book a verified dummy ticket for :
                    <span
                      style={{
                        textDecoration: "line-through",
                        // fontFamily: "Poppins",
                      }}
                    >
                      $10{" "}
                    </span>{" "}
                    <b> $1 only </b>
                  </h1>

                  {/* <video src={process.env.PUBLIC_URL + "/canva-video.mp4"} width="100" height="60"  autoplay="true" /> */}

                  <ul class="list-group list-group-flush">
                    <li class="list-group-item">
                      {" "}
                      <ApprovalIcon /> Book Now with $1.
                    </li>
                    {/* <li class="list-group-item">
                            {" "}
                            <ApprovalIcon /> Advance ticket is a dummy ticket
                            for visa applications.
                          </li> */}
                    <li class="list-group-item">
                      {" "}
                      <AirplaneTicketIcon /> Receive ticket within 60 minutes
                    </li>
                    <li class="list-group-item">
                      {" "}
                      <VerifiedIcon /> Verify PNR code. Read{" "}
                      <a href="https://advance-ticket.com/faq"> more on dummy tickets</a> .
                    </li>
                    <li class="list-group-item">
                      {" "}
                      <MonetizationOnIcon /> Pay remaining amount ($7) within
                      24hrs of receiving ticket.
                    </li>
                  </ul>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </Form.Group>
    </Form>
  );
}
export default HomeForm;
