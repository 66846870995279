import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";

// import MailIcon from "@mui/icons-material/Mail";
import PhoneIcon from "@mui/icons-material/Phone";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import BusinessIcon from "@mui/icons-material/Business";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import RssFeedIcon from "@mui/icons-material/RssFeed";

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary">
      {"  "} <WhatsAppIcon /> +91-7071341203 {". Follow us on :  "}
      <a href="https://twitter.com/AdvanceTicket23">
        <TwitterIcon />
      </a>
      <a href="https://www.linkedin.com/company/advance-ticket/ ">
        <LinkedInIcon fontSize="medium" />
      </a>
      <a href="https://www.instagram.com/advance_ticket/ ">
        <InstagramIcon fontSize="medium" />
      </a>
      <a href="https://advance-ticket.com/blogs">
        <NewspaperIcon />
        <BorderColorIcon />
      </a>
      <a href="https://advance-ticket.blogspot.com/">
        <RssFeedIcon />
      </a>
      <FacebookIcon fontSize="medium" />
      {". Copyright © "}
      <Link color="inherit" href="/">
        advance ticket
      </Link>{" "}
      {new Date().getFullYear()}
      {".  "}
      <PhoneIcon /> +91-7071341203 {"  "}
      {". "}
      <BusinessIcon /> Koramangala 7th block, Bangalore.
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function StickyFooter() {
  return (
    <ThemeProvider theme={defaultTheme}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          // width:"80%"
        }}
      >
        <Box
          component="footer"
          sx={{
            py: 2,
            px: 2,
            mt: "auto",
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[200]
                : theme.palette.grey[800],
          }}
        >
          <Container>
            <Typography variant="body1"></Typography>
            <Copyright />
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
