import "./App.css";
import Home from "./home.tsx";
import Payment from "./payment.tsx";
import HowPage from "./howPage.tsx";
import FaqPage from "./faqPage.tsx";
import FaqSchengenPage from "./faqSchengenPage.tsx"
import BlogPage from "./blogPage.tsx";
import Contact from "./contact.tsx";

import React from "react";
import { Route, Routes } from "react-router-dom";
import Final from "./final.tsx";
// import "rsuite/dist/rsuite.min.css";
import { useState } from "react";
import Thanks from "./thanks.tsx";
import DummyTicketForVisa from "./blogs/dummy_ticket_for_visa.tsx";
import DummyTicketForVisaIndiaAirIndia from "./blogs/dummy_ticket_for_visa-india-air-india.tsx";
import DummyTicketForVisaIndiaIndigo from "./blogs/dummy_ticket_for_visa-india-indigo.tsx";
import DummyTicketForSchengenVisa from "./blogs/dummy_ticket_for_schengen_visa.tsx";



export const UserContext = React.createContext(null);

function App() {
  const formValue = {
    firstName: "fffff",
    lastName: "",
    title: "",
    fromCity: "",
    destCity: "",
    date: "",
    email: "",
    nationality: "",
    passportIssuingCountry: "",
    passportNo: "",
    passportExpiry: "",
    dob: "",
    mobileNumber: "",
  };
  const [user, setUser] = useState(formValue);

  return (
    <div>
      <UserContext.Provider value={{ user, setUser }}>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/home" element={<Home />} />
          <Route exact path="/payment" element={<Payment />} />
          <Route exact path="/final" element={<Final />} />
          <Route exact path="/how" element={<HowPage />} />
          <Route exact path="/faq" element={<FaqPage />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/thanks" element={<Thanks></Thanks>} />
          <Route exact path="/blogs" element={<BlogPage />} />

          <Route exact path="/privacy" element={<Contact/>} />
          <Route exact path="/help" element={<Contact/>} />
          <Route exact path="/terms-of-conditions" element={<Contact/>} />

          <Route exact path="/faq-schengen-visa" element={<FaqSchengenPage />} />


          <Route exact path="/simple-steps-to-buy-dummy-ticket-for-visa" element={<HowPage />} />
          <Route exact path="/how-to-buy-dummy-ticket-for-visa" element={<HowPage />} />
          <Route exact path="/how-long-is-dummy-ticket-valid" element={<HowPage />} />


         <Route exact path="/dummy-ticket-with-pnr" element={<Home />} />
         <Route exact path="/dummy-ticket-with-pnr-indigo" element={<Home />} />
         <Route exact path="/dummy-ticket-with-pnr-spicejet" element={<Home />} />
         <Route exact path="/best-dummy-ticket-website" element={<Home />} />
         <Route exact path="/cheap-dummy-ticket" element={<Home />} />
         <Route exact path="/cheap-dummy-indigo" element={<Home />} />
         <Route exact path="/cheap-dummy-air-india" element={<Home />} />

         <Route exact path="/cheap-dummy-ticket-for-visa" element={<Home />} />
          <Route exact path="/cheapest-dummy-ticket" element={<Home />} />
          <Route exact path="/cheapest-dummy-ticket-for-visa" element={<Home />} />
          {/* <Route exact path="/dummy-ticket-for-visa" element={<Home />} /> */}
          <Route exact path="/dummy-ticket-for-visa-indigo" element={<Home />} />
          <Route exact path="/dummy-ticket-for-visa-air-india" element={<Home />} />
          <Route exact path="/dummy-ticket-for-visa-spicejet" element={<Home />} />
          <Route exact path="/dummy-ticket-for-visa-yatra" element={<Home />} />
          <Route exact path="/yatra-dummy-ticket" element={<Home />} />




          <Route exact path="/dummy-ticket-for-visa" element={<DummyTicketForVisa />} />
          {/* <Route exact path="/dummy-ticket-for-visa-application" element={<DummyTicketForVisa />} /> */}
          <Route exact path="/dummy-ticket-for-visa-india-air-india" element={<DummyTicketForVisaIndiaAirIndia />} />
          <Route exact path="/dummy-ticket-for-visa-india" element={<DummyTicketForVisaIndiaAirIndia />} />
          <Route exact path="/dummy-ticket-for-visa-application-india" element={<DummyTicketForVisaIndiaAirIndia />} />



          <Route exact path="/dummy-ticket-for-visa-india-indigo" element={<DummyTicketForVisaIndiaIndigo />} />
          <Route exact path="/dummy-ticket-for-visa-india-indigo-pdf" element={<DummyTicketForVisaIndiaIndigo />} />
          <Route exact path="/dummy-ticket-for-visa-india-indigo-online" element={<DummyTicketForVisaIndiaIndigo />} />
          <Route exact path="/how-to-book-dummy-ticket-schengen-visa" element={<DummyTicketForSchengenVisa />} />
          <Route exact path="/how-to-book-dummy-tickets-for-schengen-visa" element={<DummyTicketForSchengenVisa />} />



          <Route exact path="/dummy-ticket-for-schengen-visa" element={<FaqPage />} />
          <Route exact path="/dummy-ticket-schengen-visa" element={<FaqPage />} />
          <Route exact path="/dummy-ticket-for-visa" element={<FaqPage />} />


          <Route exact path="/dummy-ticket-for-visa-application" element={<FaqPage />} />






        </Routes>
      </UserContext.Provider>
    </div>
  );
}

export default App;

// <script type="text/javascript">
//     (function(c,l,a,r,i,t,y){
//         c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
//         t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
//         y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
//     })(window, document, "clarity", "script", "joqyh72x0w");
// </script>
