import React from "react";
import NavComponent from "./navbar.tsx";

import DummyTicketForVisa from "./blogs/dummy_ticket_for_visa.tsx";
import DummyTicketForVisaIndiaAirIndia from "./blogs/dummy_ticket_for_visa-india-air-india.tsx";
import DummyTicketForSchengenVisa from "./blogs/dummy_ticket_for_schengen_visa.tsx";
import StickyFooter from "./components/bottom.tsx";

function BlogPage() {
  return (
    <div
      style={{
        height: "100%",
        backgroundColor: "#aaedff",
        //  backgroundImage:
        //    "url('https://images.unsplash.com/photo-1513002749550-c59d786b8e6c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2848&q=80')",
      }}
    >

      <NavComponent />
      <DummyTicketForVisa />
      <br /> <br />
      <DummyTicketForVisaIndiaAirIndia />
      <br />
      <br />
      <DummyTicketForSchengenVisa />
      
      <br />
      {/* <DummyTicketForVisaIndiaIndigo /> */}
    <StickyFooter/>

    </div>
  );
}

export default BlogPage;
