import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import React from "react";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
function NavComponent() {
  return (
    <Navbar className="bg-body-tertiary">
      <Container
        style={{
          height: "6rem",
          marginLeft: "1%",
          marginRight: "1%",
          width: "100%",
          // backgroundColor: "red",
        }}
      >
        <Navbar.Brand>
          {" "}
          <div
            style={{
              // maxWidth: "10rem",
            }}
          >
            {/* <img
              src="https://dummyflights.com/dummy/assets/images/layesrs.png"
              width={"100%"}
              height={"100%"}
              // max-height="10px"
              class="image_part"
              alt="logo2"
            ></img>{" "} */}
            <FlightTakeoffIcon fontSize="large" />
          </div>
        </Navbar.Brand>
        <Navbar.Brand href="/"> Advance Tickets </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto"></Nav>
        </Navbar.Collapse>
        <Navbar.Collapse
          style={{ marginLeft: "10%", marginRight: "5%" }}
          className="justify-content-end"
        >
          {/* <Nav.Link href="/contact">Contact Us</Nav.Link> */}

          <NavDropdown
            style={{ marginLeft: "10%", marginRight: "25%" }}
            title=" Why buy ?  "
            id="basic-nav-dropdown"
          >
            <NavDropdown.Item href="https://advance-ticket.com/how"> How it works</NavDropdown.Item>
            <NavDropdown.Item href="https://advance-ticket.com/faq">FAQ</NavDropdown.Item>
            <NavDropdown.Item href="https://advance-ticket.com/blogs">Blogs</NavDropdown.Item>
            <NavDropdown.Item href="https://advance-ticket.com/contact"> Support </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item href="https://gackpacker.com/">
              More Services
            </NavDropdown.Item>
          </NavDropdown>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavComponent;
