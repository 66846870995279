import React from "react";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";



export default function FaqText({ title, desc }) {
    return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>
            {" "}
            <h2 style={{ fontSize: "18px" }}>
              {" "}
              {title} {" "}
            </h2>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <li class="list-group-item" style={{ backgroundColor: "#eafdff" }}>
              <h3 style={{ fontSize: "18px" }}> </h3>
              <br /> <QuestionAnswerIcon /> {}
              <div dangerouslySetInnerHTML={{ __html: desc }} />
            </li>
          </Typography>
        </AccordionDetails>
      </Accordion>
    );
  }
  