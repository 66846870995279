import React from "react";
import NavComponent from "./navbar.tsx";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import { UserContext } from "./App";
import emailjs from "emailjs-com";
import Alert from "@mui/material/Alert";
import StickyFooter from "./components/bottom.tsx";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase, ref, set } from "firebase/database";



// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();


// Import the functions you need from the SDKs you need


// TODO: Replace the following with your app's Firebase project configuration
// See: https://firebase.google.com/docs/web/learn-more#config-object

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCFzf91zwa4hFdSgpGpGZIa1Tit24ZQkwo",
  authDomain: "advance-ticket.firebaseapp.com",
  projectId: "advance-ticket",
  storageBucket: "advance-ticket.appspot.com",
  messagingSenderId: "327180941839",
  appId: "1:327180941839:web:4f0961c6d26e8ca641817a",
  measurementId: "G-QX5Z777LZG",
  databaseURL: "https://advance-ticket-default-rtdb.firebaseio.com",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// const analytics = getAnalytics(app);

// Initialize Realtime Database and get a reference to the service
const database = getDatabase(app);
// nsole.log(Country.getAllCountries())
// console.log(State.getAllStates())

function Final() {
  const navigate = useNavigate();
  const { user, setUser } = useContext(UserContext);
  const [showAlert, setShowAlert] = useState(false);


  function writeUserData() {
    const db = getDatabase();
    set(ref(db, "users/" + user.firstName), {
      firstName: user.firstName,
      // lastName: user.lastName,
      title: user.title,
      fromCity: user.fromCity,
      destCity: user.destCity,
      date: user.date,
      email: user.email,
      nationality: user.nationality,
      passportIssuingCountry:  user.passportIssuingCountry,
      passportNo:  user.passportNo,
      passportExpiry:  user.passportExpiry,
      dob:  user.dob,
      mobileNumber:  user.mobileNumber,
      // profile_picture : imageUrl
    });
  }

  const handleClick = () => {
    //  e.preventDefault();
    console.log("Confirming passport");
    console.log(user);
    writeUserData();
    if (user["passportNo"] === "" || user["dob"] === "" ) {
      // alert('please fill details!');
      setShowAlert(true);
      // setShowAlert({visible:true},()=>{
      //   window.setTimeout(()=>{
      //     setShowAlert({visible:false})
      //   },2000)
      // });
      setShowAlert(true);
      setTimeout(() => {
        /// ---> 2 seconds later which is closing
        setShowAlert(false);
      }, 2000);
      return;
    }
    sendEmail();
    navigate("/thanks");
  };
  const sendEmail = () => {
    // return;
    // e.preventDefault();    //This is important, i'm not sure why, but the email won't send without it

    emailjs
      .send("service_nnvyyn4", "template_t81hk2j", user, "U-mN1d2ubkdUSV2Tz")
      .then(
        (result) => {
          // window.location.reload()  //This is if you still want the page to reload (since e.preventDefault() cancelled that behavior)
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  const handleChange = (event) => {
    const value = event.target.value;
    // console.log(event.target.value);

    setUser((previousState) => {
      return { ...previousState, [event.target.name]: value };
    });
    // console.log(user);
    // setUser({ firstName:  event.target.value })
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get("email"),
      password: data.get("password"),
    });
  };

  return (
    <div
      style={{
        height: "100%",
        backgroundColor: "#aaedff",
        // backgroundImage:
          // "url('https://images.unsplash.com/photo-1513002749550-c59d786b8e6c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2848&q=80')",
      }}
    >
      <NavComponent />

      <ThemeProvider theme={defaultTheme}>
        <Container
          component="main"
          maxWidth="xs"
             
          style={{ backgroundColor: "white", opacity: "0.9" , width:"96%"}}
        >
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {/* <CelebrationIcon/> */}
            {/* <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar> */}
            {/* <Card.Img variant="top" src="https://static.vecteezy.com/system/resources/previews/008/149/282/large_2x/thank-you-papercut-sky-landscape-banner-with-hot-air-balloon-could-and-birds-for-business-marketing-flyers-banners-presentations-and-posters-illustration-vector.jpg" /> */}
            <br />
            <Typography component="h1" variant="h5">
              Thanks for booking!
            </Typography>
            <br />
            <Typography component="h3" variant="subtitle1">
              - We need few <b> passport related details </b> to book the international
              ticket.
            </Typography>
            <Box component="form" noValidate sx={{ mt: 3 }}>
              <b>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <h6> Nationality</h6>{" "}
                    <TextField
                      autoComplete="given-name"
                      name="nationality"
                      required
                      fullWidth
                      id="nationality"
                      label="Nationality"
                      autoFocus
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <h6> Passport Issuing Country </h6>{" "}
                    <TextField
                      required
                      fullWidth
                      name="passportIssuingCountry"
                      label="Passport Issuing Country"
                      // type="password"
                      id="passportIssuingCountry"
                      autoComplete="new-password"
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <h6> Passport Number </h6>{" "}
                    <TextField
                      required
                      fullWidth
                      id="passportno"
                      label="Passport No"
                      name="passportNo"
                      autoComplete="family-name"
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <h6> Date of Birth </h6>{" "}
                    <TextField
                      required
                      fullWidth
                      id="dob"
                      // label="Date of Birth"
                      name="dob"
                      // autoComplete="family-name"
                      type="date"
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <h6> Passport Expiry </h6>{" "}
                    <TextField
                      required
                      fullWidth
                      id="passportExpiry"
                      // label="Date of Birth"
                      name="passportExpiry"
                      // autoComplete="family-name"
                      type="date"
                      onChange={handleChange}
                    />
                  </Grid>

                  {/* <Grid item xs={12}>
                    <h6> Whatsapp Number  </h6>{" "}
                    <TextField
                      fullWidth
                      id="mobileNumber"
                      label="Number" 
                      name="mobileNumber"
                      // autoComplete="family-name"
                      type="number"
                      onChange={handleChange}
                    />
                  </Grid> */}
                  {/* <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox value="allowExtraEmails" color="primary" />}
                  label="I want to receive inspiration, marketing promotions and updates via email."
                />
              </Grid> */}
                </Grid>
              </b>
              {showAlert && (
                <Alert severity="warning">Please fill all details!</Alert>
              )}
              <Button
                // type="submit"
                fullWidth
                variant="contained"
                onClick={handleClick}
                sx={{ mt: 3, mb: 2 }}
              >
                Submit
              </Button>
            </Box>
          </Box>
          {/* <Copyright sx={{ mt: 5 }} /> */}
        </Container>
      </ThemeProvider>

      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
<StickyFooter/>
      {/* <p> Scan QR to Pay INR 800 </p> */}
      {/* <img src="https://dummyflights.com/dummy/assets/images/layesrs.png"  class="image_part"  alt="logo2"></img> */}
    </div>
  );
}

export default Final;
