import React from "react";

function DummyTicketForSchengenVisa() {
  return (
    <div
      style={{
        height: "100%",

        //  backgroundImage:
        //  "url('https://images.unsplash.com/photo-1513002749550-c59d786b8e6c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2848&q=80')",
      }}
    >
      {/* <NavComponent /> */}

      <ul
        class="list-group list-group-flush"
        style={{
          // backgroundColor: "#eafdff",
          backgroundColor: "#aaedff",
          opacity: "0.9",
          padding: "3%",
          // lineBreak:""
          // fontFamily: "Helvetica"
          // margin: "1%",
          fontSize: "20px",
        }}
      >
        {/* <b> Sample:  </b> */}
        <img
          style={{ width: "30%", alignSelf: "center" }}
          src={
            process.env.PUBLIC_URL +
            "/dummy-ticket-for-visa-application-sample.jpg"
          }
        ></img>
        <br />

        <h1 style={{ fontSize: "33px" }}>
          {" "}
          What are the simple steps to buy dummy flight tickets for schengen
          Visa?{" "}
        </h1>
        <br />

        <li
          class="list-group-item"
          style={{ backgroundColor: "#eafdff", padding: "5%" }}
        >
          To buy dummy flight tickets for a Schengen Visa application, you can
          follow these simple steps:
          <br /> <b>- Gather Information:</b>
          Determine your intended travel dates and the Schengen countries you
          plan to visit. This information will be needed to create the dummy
          flight itinerary. Use a Travel Agency or Online Service:
          <br />
          - Look for reputable travel agencies or online services that offer the
          creation of dummy flight itineraries. There are various websites that
          provide this service, such as Visa Reservation, Schengen Visa
          Itinerary, or Flight Reservation for Visa.
          <br /> <b>- Select the Desired Itinerary:</b>
          Choose the destination airports and travel dates for your dummy flight
          itinerary. Ensure that the information aligns with your visa
          application and the details you provided in your visa application
          form.
          <br /> <b>- Provide Personal Details:</b>
          You may be required to provide your name, passport details, contact
          information, and the purpose of your travel. Be accurate in providing
          this information.
          <br /> <b>- Pay for the Service: </b>
          Most online services charge a fee for creating a dummy itinerary. Pay
          the required amount using a valid payment method, such as a credit
          card.
          <br /> <b>- Receive the Itinerary: </b>
          Once your payment is processed, you will receive the dummy flight
          itinerary via email. Make sure to check all the details for accuracy.
          <br /> <b>- Print the Itinerary: </b>
          After receiving the itinerary, print it out. This printout will be
          part of the documentation you submit with your Schengen Visa
          application.
          <br /> <b>- Additional Documentation: </b>
          In addition to the dummy flight itinerary, you will need other
          supporting documents for your Schengen Visa application, such as
          travel insurance, accommodation reservations, proof of financial
          means, and a completed visa application form.
          <br /> <b>- Submit Your Visa Application: </b>
          Along with the dummy flight itinerary and other required documents,
          submit your visa application to the appropriate Schengen country's
          embassy or consulate. Be sure to follow their specific application
          procedures and requirements.
          <br /> <b>- Keep Records: </b>
          Keep records of all documents, including the dummy flight itinerary
          and your visa application. You may need them for reference or if you
          have to attend an interview at the embassy or consulate. It's
          essential to remember that a dummy flight itinerary is not a real
          ticket and cannot be used for actual travel. <br /> <br /> Its purpose
          is solely to demonstrate your travel plans and intentions as part of
          your Schengen Visa application. Make sure to be honest and accurate in
          all aspects of your visa application to avoid any complications or
          delays in the process
        </li>
      </ul>
    </div>
  );
}

export default DummyTicketForSchengenVisa;
