import React from "react";
import FaqText from "./faqText.tsx";

export default function FaqTextSchengenVisa({ title, desc }) {
  return (
    <div>
      <ul
        class="list-group list-group-flush"
        style={{
          backgroundColor: "#eafdff",
          opacity: "0.9",
          padding: "5%",
          // margin: "1%",
          margin: "3%",
          fontSize: "20px",
        }}
      >
        <br />
        <h2 style={{ fontSize: "24px" }}> FAQ on Schengen Visa</h2> <br />
        <FaqText
          title={"       	Can i use dummy ticket for schengen visa"}
          desc={` Yes,Instead of using fake tickets, consider these alternatives: <br/>
  
               - Flight Itinerary or Reservation: Make a legitimate flight itinerary or reservation. Many airlines and booking websites allow you to hold a flight reservation for a certain period without purchasing the ticket.
               <br/>
             -  Travel Agency Assistance: Some travel agencies or specialized services offer legitimate flight itineraries for visa applications. These can provide the necessary documentation without purchasing the actual ticket.
              <br/>
              - Full Refundable Tickets: Consider booking fully refundable or flexible tickets. While they might be more expensive, you can cancel them after obtaining the visa without losing money.
              <br/>
              It's crucial to be honest and provide authentic documents when applying for a visa. Falsifying information can lead to serious consequences and can adversely affect your ability to travel in the future. Always ensure that the information and documentation provided in visa applications are genuine and meet the requirements of the issuing authorities.`}
        />
        <FaqText
          title={"       How to book dummy tickets for schengen visa?"}
          desc={`It's important to note that booking "dummy" tickets—meaning fake or non-genuine flight reservations—for a Schengen visa application is not recommended. Providing false information in a visa application can lead to rejection, legal consequences, and potential difficulties in obtaining visas for future travel.
            <br/>
              Instead of using fake or fraudulent tickets, consider the following legitimate options:  <br/>
              <br/>
              Hold Reservations: Many airlines or travel agencies allow you to hold flight reservations for a certain period without payment. These reservations serve as proof of your intended travel plans and can be used for the visa application.
              <br/>
              Use Reputable Travel Agencies: Some travel agencies or specialized services provide legitimate flight itineraries for visa applications. They offer reservation services that comply with visa requirements without the need to purchase actual tickets.`}
        />
        <FaqText
          title={"        Any Guide to Schengen flight reservation ? "}
          desc={`When applying for a Schengen visa, having a flight reservation is often required as proof of your intended travel plans. Here’s a guide on obtaining a Schengen flight reservation for your visa application:
            <br/>
              Select a Reliable Airline or Booking Service: Choose a reputable airline or an online travel agency to book your flight reservation. Make sure the service you use is recognized and legitimate.
              <br/>
              Hold Reservations or Flexible Tickets: Look for options that allow you to hold a flight reservation without paying immediately. Some airlines or booking platforms offer the option to hold the booking for a certain period (usually 24 to 72 hours) without payment.
              <br/>
              Fully Refundable Tickets: Consider purchasing fully refundable or flexible tickets. Although they can be more expensive, these tickets allow you to cancel or change your booking after obtaining the visa without financial loss.
              <br/>
              Follow Visa Requirements: Ensure that the flight reservation or itinerary you obtain complies with the requirements set by the specific Schengen country’s consulate or embassy where you're applying. These requirements may differ slightly between countries.
              <br/>
              Personal Information: When making the flight reservation, enter your personal details exactly as they appear in your passport. Consistency in the information provided is crucial for your visa application.
              <br/>
              Dates and Itinerary: Your flight reservation should correspond with the dates you intend to travel. Ensure the itinerary includes a round trip or the intended duration of your stay within the Schengen Zone.
              <br/>
              Proof of Reservation: Once you have the reservation, make sure to have a printed or electronic copy of the flight itinerary or reservation to submit as part of your visa application.
              <br/>
              Double-Check Details: Review the details of your reservation to ensure accuracy. Mistakes in flight details or personal information could cause issues during the visa application process.
              <br/>
              Consulate or Embassy Requirements: Verify the specific consulate or embassy’s guidelines regarding the acceptance of flight reservations for visa applications. Some may have specific preferences or requirements regarding the format or validity of flight reservations.
              <br/>
              Documentation Supporting Intent to Return: If you have a return ticket or further travel plans after your Schengen visit, include supporting documentation (like a booked return flight) to demonstrate your intention to leave the Schengen Zone before the visa expires.
              <br/>
              Always ensure that your flight reservation meets the visa application requirements and accurately reflects your intended travel plans. It's important to be truthful and provide genuine documentation when applying for a Schengen visa.`}
        />
        <FaqText
          title={
            "        How to book 	flight reservation for visa without payment ?"
          }
          desc={`To book a flight reservation for a visa application without making an immediate payment, follow these steps:
            <br/>
            Choose Airlines or Agencies Offering Reservation Holds: Look for airlines or online travel agencies that offer the option to hold flight reservations without immediate payment. Not all airlines or agencies provide this service, so you might need to check several options.
            <br/>
            Visit Airline Websites or Use Booking Platforms: Visit the website of the airline you're considering or use reputable booking platforms. Look for the option to 'Hold' or 'Reserve' the flight without payment.
            <br/>
            Select the Flight and Itinerary: Enter your travel details such as departure and arrival locations, dates, and times. Choose the desired flight and itinerary that fits your travel plans.
            <br/>
            Check for Reservation Hold Option: During the booking process, look for an option that allows you to reserve the selected flight without payment. This option might be labeled as "Hold Reservation," "Reserve without Payment," or something similar.
            <br/>
            Review Hold Period and Terms: Note the specific hold period offered by the airline or booking service. It's usually a limited time frame, typically ranging from 24 hours to 72 hours. Ensure you understand any terms or conditions related to the hold.
            <br/>
            Enter Personal Details: Provide your personal information accurately, ensuring it matches the details in your passport. This is crucial for your visa application.
            <br/>
            Complete the Hold Process: Follow the instructions provided by the airline or booking service to complete the reservation hold process. This may involve entering your contact information or creating an account on their platform.
            <br/>
            Confirmation and Documentation: Once the reservation is on hold, you'll typically receive a confirmation email or reference number. Ensure you have a copy of this reservation confirmation, as it's required for your visa application.
            <br/>
            Adhere to Hold Period: Be mindful of the hold period. If required, complete your visa application within the specified time frame and ensure the flight reservation details meet the consulate or embassy's requirements.
            <br/>
            Remember, the option to hold a flight reservation without payment might vary among different airlines or booking platforms. Not all airlines or agencies provide this service, so you may need to explore multiple options to find one that meets your needs for a visa application.`}
        />
        <br />
        <hr />
        <br />
        <h2 style={{ fontSize: "23px" }}>
          {" "}
          More questions on dummy ticket and schengen visa
        </h2>{" "}
        <br />
        <FaqText
          title={
            "Why is a confirmed travel itinerary necessary for a Schengen visa application?"
          }
          desc={`A confirmed travel itinerary is vital as it demonstrates the intention and feasibility of the trip, providing authorities with a clear overview of your travel plans.`}
        />
        <FaqText
          title={
            "What documents are required to provide a confirmed travel itinerary for a Schengen visa?"
          }
          desc={`Essential documents include flight reservations, hotel bookings, and a detailed plan showing the intended travel within the Schengen Area.`}
        />
        <FaqText
          title={
            "Is it mandatory to purchase actual flight tickets for a Schengen visa application or are flight reservations sufficient?"
          }
          desc={`Flight reservations are generally enough for the visa application process; actual tickets may not be required until the visa is approved.`}
        />
        <FaqText
          title={
            "How far in advance should flight reservations be made for a Schengen visa application?"
          }
          desc={`It's recommended to make flight reservations closer to the visa application date to align with the intended travel dates.`}
        />
        <FaqText
          title={
            "Are hotel bookings necessary for the entire duration of the intended stay in the Schengen Area for a visa application?"
          }
          desc={`Typically, reservations for accommodations for the entire planned duration of the stay are required.
  
            `}
        />
        <br />
        <img
          style={{ width: "60%", alignSelf: "center" }}
          src={
            process.env.PUBLIC_URL +
            "/dummy-ticket-for-visa-application-sample.jpg"
          }
        ></img>
        
        <br />
        <FaqText
          title={
            "What happens if there are changes to the travel itinerary after obtaining a Schengen visa?"
          }
          desc={`Minor changes might be acceptable, but significant alterations might require informing the relevant authorities or, in some cases, applying for an updated visa.`}
        />
        <FaqText
          title={
            "Can the travel itinerary for a Schengen visa application include multiple countries within the Schengen Area?"
          }
          desc={`Yes, it's acceptable to include multiple countries in the travel itinerary, outlining the intended visits.`}
        />
        <FaqText
          title={
            "What level of detail is needed in the travel itinerary for a Schengen visa application?"
          }
          desc={`The itinerary should provide comprehensive details including dates, destinations, and transportation methods for a clear overview of the travel plan.`}
        />
        <br />
        <FaqText
          title={
            "How does one obtain a confirmed travel itinerary without purchasing actual tickets or making non-refundable bookings?"
          }
          desc={`Travel agencies or booking platforms often provide reservations without the necessity of purchasing tickets.`}
        />
        <FaqText
          title={
            "What are the consequences of providing false or inaccurate information in the travel itinerary for a Schengen visa application?"
          }
          desc={`Providing false information might lead to rejection of the visa application and could potentially impact future travel plans.`}
        />
        <FaqText
          title={
            "Is travel insurance a part of the required documentation for a Schengen visa application alongside the confirmed travel itinerary?"
          }
          desc={` Yes, travel insurance is generally a separate requirement in addition to the confirmed travel itinerary.`}
        />
        <FaqText
          title={
            "Can a visa application be rejected if the travel itinerary is not comprehensive or lacks necessary details?"
          }
          desc={`A poorly detailed or incomplete itinerary might lead to rejection as it's crucial for authorities to understand the travel plans.`}
        />
        <br />
        <FaqText
          title={
            "Are there specific templates or formats to follow for presenting the travel itinerary in a Schengen visa application?"
          }
          desc={`Embassies typically don't mandate specific formats, but they may have guidelines or expectations for the presentation of the itinerary.`}
        />
        <FaqText
          title={
            "Do minors applying for a Schengen visa need a separate travel itinerary?"
          }
          desc={`Minors usually need a separate itinerary or are included in their guardian's plan for the visa application.`}
        />
        <FaqText
          title={
            "What advice or suggestions can be provided for creating a reliable travel itinerary for a Schengen visa application?"
          }
          desc={`Ensure accuracy, include all planned details, and only use legitimate sources for reservations to create a comprehensive and reliable travel itinerary for the visa application.`}
        />
      </ul>
    </div>
  );
}
