import React from "react";
import Faq from "./components/faq.tsx";
import NavComponent from "./navbar.tsx";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import StickyFooter from "./components/bottom.tsx";
import FaqText from "./components/faqText.tsx";
import FaqTextSchengenVisa from "./components/faqSchengenVisa.tsx";

function FaqPage() {
  return (
    <div
      style={{
        height: "100%",
        backgroundColor: "#aaedff",
        // backgroundImage:
        //   "url('https://images.unsplash.com/photo-1513002749550-c59d786b8e6c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2848&q=80')",
      }}
    >
      <NavComponent />
      <br />
      {/* <div
        style={{
          backgroundColor: "#eafdff",
          opacity: "0.9",
          padding: "2%",
          // margin: "1%",
          margin: "3%",
          fontSize: "20px",
        }}
      >
      </div> */}
      <ul
        class="list-group list-group-flush"
        style={{
          backgroundColor: "#eafdff",
          opacity: "0.9",
          padding: "5%",
          // margin: "1%",
          margin: "3%",
          fontSize: "20px",
        }}
      >
        <Faq />
        <br />
        <hr />
        <br />
        {/* <b> Sample:  </b> */}
        {/* <img
          style={{ width: "40%", alignSelf: "center" }}
          src={process.env.PUBLIC_URL + "/dummy-ticket-for-visa-application-sample.jpg"}
        ></img> */}
        <b>
          {" "}
          <h2 style={{ fontSize: "25px" }}>
            {" "}
            Other FAQ on dummy ticket:{" "}
          </h2>{" "}
        </b>
        <br />
        <FaqText
          title={"Is it legal to apply for Schengen visa with dummy tickets?"}
          desc={`Using dummy tickets or flight itineraries when applying for a
        Schengen Visa is generally not illegal, as long as they are used
        solely for the purpose of the visa application process and not
        for actual travel. However, it is essential to be transparent
        and honest in your visa application, as providing false
        information or fraudulent documents can result in your
        application being rejected or even lead to visa fraud charges.
        <br />
        Here are some important points to consider:
        <br />
        - Visa Application Purpose: Dummy tickets or flight itineraries
        are often used in visa applications to demonstrate your intent
        to leave the Schengen Area after your visit. They are considered
        acceptable as supporting documents in this context.
        <br />
        - Temporary and Non-Refundable: Dummy tickets are typically
        temporary reservations that do not result in a financial
        commitment. They are often canceled within a specific time frame
        if not converted into actual bookings. Be aware that the fees
        paid for creating these dummy itineraries are usually
        non-refundable.
        <br />
        - Accuracy and Honesty: It is crucial to provide accurate and
        truthful information in your visa application. Falsifying
        information or using fraudulent documents can have serious
        consequences, including visa rejection, visa revocation, and
        potential travel bans.
        <br />
        - Verification: Embassies and consulates may verify the
        authenticity of the documents provided in your application,
        including dummy tickets. If discrepancies are found, it could
        lead to complications with your visa application.
        <br />
        - Alternative Proof: While dummy tickets are commonly used,
        other forms of proof, such as actual flight reservations, can
        also be accepted. Be sure to check the specific requirements of
        the Schengen country's embassy or consulate where you are
        applying for your visa.
        <br /> <br />
        In summary, using dummy tickets for a Schengen Visa application
        is not illegal if they are used in good faith and solely for
        visa application purposes. However, it is essential to follow
        the guidelines and requirements of the specific Schengen
        country's embassy or consulate where you are applying and to be
        honest in your application. Always consult the official website
        of the embassy or consulate for the most up-to-date and accurate
        information regarding visa application requirements.`}
        />
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>
              {" "}
              <h2 style={{ fontSize: "18px" }}>
                {" "}
                Why shouldn’t you buy flight tickets before visa approved?{" "}
              </h2>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <li
                class="list-group-item"
                style={{ backgroundColor: "#eafdff" }}
              >
                {" "}
                <br /> <QuestionAnswerIcon />
                Purchasing flight tickets before your visa is approved can be
                risky and is generally not advisable for several reasons:
                <br />
                - Visa Denial: There's always a possibility that your visa
                application may be denied. If you've already bought
                non-refundable tickets, you risk losing a significant amount of
                money.
                <br />
                - Financial Loss: Airline tickets can be expensive, and if your
                visa application is denied, you may not be able to recoup the
                cost of the tickets. Cancellation fees or penalties can further
                add to your financial losses.
                <br />
                - Lack of Flexibility: Once you've bought your tickets, you may
                be locked into specific travel dates. If your visa is delayed or
                denied, you won't have the flexibility to adjust your travel
                plans.
                <br />
                - Unpredictable Processing Times: Visa processing times can vary
                significantly depending on the type of visa, your nationality,
                and the embassy or consulate's workload. It's challenging to
                predict exactly when your visa will be approved.
                <br />
                - Change in Plans: Sometimes, travel plans change for various
                reasons, such as personal emergencies or changes in your
                itinerary. If you've already purchased tickets, you may face
                difficulties in making alterations or obtaining refunds.
                <br />- Overcommitting: By buying tickets before your visa is
                approved, you may be overcommitting to your travel plans, making
                it difficult to explore alternative options if your visa is
                denied or delayed
              </li>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>
              {" "}
              <h2 style={{ fontSize: "18px" }}>
                {" "}
                What are the simple steps to buy dummy flight tickets for
                schengen Visa?{" "}
              </h2>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <li
                class="list-group-item"
                style={{ backgroundColor: "#eafdff" }}
              >
                <br /> <QuestionAnswerIcon />
                To buy dummy flight tickets for a Schengen Visa application, you
                can follow these simple steps:
                <br />
                - Gather Information:
                <br />
                Determine your intended travel dates and the Schengen countries
                you plan to visit. This information will be needed to create the
                dummy flight itinerary. Use a Travel Agency or Online Service:
                <br />
                - Look for reputable travel agencies or online services that
                offer the creation of dummy flight itineraries. There are
                various websites that provide this service, such as Visa
                Reservation, Schengen Visa Itinerary, or Flight Reservation for
                Visa.
                <br />
                - Select the Desired Itinerary:
                <br />
                Choose the destination airports and travel dates for your dummy
                flight itinerary. Ensure that the information aligns with your
                visa application and the details you provided in your visa
                application form.
                <br />
                - Provide Personal Details:
                <br />
                You may be required to provide your name, passport details,
                contact information, and the purpose of your travel. Be accurate
                in providing this information.
                <br />
                - Pay for the Service:
                <br />
                Most online services charge a fee for creating a dummy
                itinerary. Pay the required amount using a valid payment method,
                such as a credit card.
                <br />
                - Receive the Itinerary:
                <br />
                Once your payment is processed, you will receive the dummy
                flight itinerary via email. Make sure to check all the details
                for accuracy.
                <br />
                - Print the Itinerary:
                <br />
                After receiving the itinerary, print it out. This printout will
                be part of the documentation you submit with your Schengen Visa
                application.
                <br />
                - Additional Documentation:
                <br />
                In addition to the dummy flight itinerary, you will need other
                supporting documents for your Schengen Visa application, such as
                travel insurance, accommodation reservations, proof of financial
                means, and a completed visa application form.
                <br />
                - Submit Your Visa Application:
                <br />
                Along with the dummy flight itinerary and other required
                documents, submit your visa application to the appropriate
                Schengen country's embassy or consulate. Be sure to follow their
                specific application procedures and requirements.
                <br />
                - Keep Records:
                <br />
                Keep records of all documents, including the dummy flight
                itinerary and your visa application. You may need them for
                reference or if you have to attend an interview at the embassy
                or consulate. It's essential to remember that a dummy flight
                itinerary is not a real ticket and cannot be used for actual
                travel. Its purpose is solely to demonstrate your travel plans
                and intentions as part of your Schengen Visa application. Make
                sure to be honest and accurate in all aspects of your visa
                application to avoid any complications or delays in the process
              </li>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <FaqText
          title={" Why i need a dummy flight ticket for schengen visa?"}
          desc={
            "Embassies don’t recommend to buy actual flight tickets. Quotes from Embassy"
          }
        />
        <br />
        <FaqText
          title={"   How long is the dummy ticket valid?"}
          desc={
            "We typically offer flight reservations(with Live PNR) valid for 7 to 14 days. Duration depends solely on the airline & not on whether you book through an online or offline agent."
          }
        />
        <FaqText
          title={" Do I pay for the full price of the flight?"}
          desc={
            "No, this is just a flight reservation so you’re not paying for the full price of the flight. You only need to  pay the flight reservation fee."
          }
        />
        <FaqText
          title={
            "        Is there a higher chance of getting a visa with an confirmed ticket than a dummy ticket?"
          }
          desc={
            "  The only difference that a Dummy ticket has is the PNR gets released (invalidated) automatically by the airline within 7-14 days. Visa refusals occur regardless of whether actual or dummy tickets are presented. "
          }
        />
        <br />
        <hr />
        <br />
        <h2 style={{ fontSize: "20px" }}>
          {" "}
          FAQ on Why you should not use Fake Travel tickets / insurance
        </h2>{" "}
        <br />
        <FaqText
          title={
            "       	Why you should not use fake travel insurance generator ?"
          }
          desc={`Using a fake travel insurance generator is highly discouraged for several significant reasons:
          <br/>
          Visa Application Rejection: When applying for a visa, providing false or fabricated documents, including travel insurance, can lead to immediate rejection. Embassies and consulates verify the authenticity of documents, and if they discover that the insurance is fake, it will negatively impact your application.
          <br/>
          Legal Consequences: Submitting falsified or fraudulent documents can have serious legal consequences. It may lead to legal charges, fines, or even travel bans, affecting your ability to visit that country in the future.
          <br/>
          No Actual Coverage: Fake travel insurance does not provide any real coverage. In case of an emergency, you'll have no financial protection or support, leaving you vulnerable to unexpected expenses, accidents, or health issues while abroad.
          <br/>
          Loss of Credibility: Using fake documents undermines your credibility. It can negatively impact your reputation and may affect your chances of obtaining visas or other travel-related services in the future.
          <br/>
          Risk to Personal Safety: Genuine travel insurance often includes coverage for medical emergencies, trip cancellations, and other unforeseen situations. Using fake insurance puts you at risk of being stranded without assistance in case of an emergency.
          <br/>
          Impact on Future Travel: Being caught using fake documents, especially for visa applications, can result in a black mark on your travel history. This may make it more challenging to obtain visas in the future, even when using legitimate documents.
          <br/>
          It's crucial to use authentic and legitimate documents, including travel insurance, when traveling abroad or applying for visas. Instead of using a fake generator, purchase actual travel insurance from reputable insurance providers. Legitimate travel insurance not only provides financial protection but also offers support in case of emergencies or unforeseen events during your trip.`}
        />
        <FaqText
          title={"Why you should not use dummy travel insurance  ?"}
          desc={`Using dummy or fake travel insurance can lead to significant problems and risks while traveling. Here's why you should avoid using dummy travel insurance:
          <br/>
          No Actual Coverage: Dummy travel insurance does not provide any real coverage or protection. In case of an emergency, accident, illness, or unexpected event while traveling, you will not have insurance support or financial coverage.
          <br/>
          Visa Application Rejection: When applying for a visa, submitting fake or dummy travel insurance can lead to immediate rejection. Embassies and consulates verify the authenticity of documents, and providing false information can result in your visa application being denied.
          <br/>
          Legal Consequences: Using fake or fraudulent documents can have serious legal repercussions. It may result in legal charges, fines, or even travel bans, impacting your future travel opportunities.
          <br/>
          Risk to Personal Safety: Authentic travel insurance often includes coverage for medical emergencies, trip cancellations, and other unforeseen situations. Using fake insurance puts you at risk of being stranded without support in case of an emergency.
          <br/>
          Loss of Credibility: Using dummy travel insurance undermines your credibility. It can negatively impact your reputation and affect your ability to obtain visas or travel-related services in the future.
          <br/>
          Impact on Future Travel: Being caught using fake documents, especially for visa applications, can create a negative mark on your travel history. This may make it more challenging to obtain visas in the future, even when using legitimate documents.
          <br/>
          It's essential to prioritize authentic and legitimate travel insurance when traveling abroad. Genuine travel insurance not only offers financial protection but also provides assistance and support in case of unforeseen events during your trip. Always purchase travel insurance from reputable and recognized insurance providers to ensure you have genuine coverage while traveling.`}
        />
        <FaqText
          title={
            "What are the risks of using fake flight tickets for a visa application?"
          }
          desc={
            "Using fake flight tickets for a visa application poses severe risks, including visa rejection, legal consequences, and future travel implications."
          }
        />
        <FaqText
          title={
            "Could using a fake flight ticket lead to visa rejection or denial?"
          }
          desc={
            "Yes, presenting a fake flight ticket could result in visa rejection or denial due to fraudulent documentation."
          }
        />
        <FaqText
          title={
            "Are there legal consequences for presenting fake documentation in a visa application?"
          }
          desc={
            "Presenting fake documentation in a visa application may lead to legal repercussions, including visa denial and potential bans from future applications."
          }
        />
        <br />
        <FaqText
          title={
            "How can using fake flight tickets impact future travel opportunities or visa applications?"
          }
          desc={
            "Using fake flight tickets can severely impact future travel opportunities, leading to credibility issues in subsequent visa applications and potential travel restrictions."
          }
        />
        <FaqText
          title={
            "Is there a difference between using a provisional itinerary and a fake flight ticket in a visa application?"
          }
          desc={
            "A provisional itinerary demonstrates intent, while a fake flight ticket presents false information, with the latter carrying more severe consequences if detected."
          }
        />
        <FaqText
          title={
            "Can immigration authorities detect fake flight tickets during the visa application process?"
          }
          desc={
            "Immigration authorities have methods to detect fake flight tickets, including verification with airlines and cross-referencing with databases, leading to potential consequences if found fraudulent."
          }
        />
        <FaqText
          title={
            "What alternatives exist to fake flight tickets for providing a confirmed travel itinerary in a visa application?"
          }
          desc={
            "Alternatives include providing a booked itinerary without payment, reservation confirmations, or travel agency-assisted provisional itineraries to showcase intended travel plans."
          }
        />
        <br />
        <img
          style={{ width: "30%", alignSelf: "center" }}
          src={process.env.PUBLIC_URL + "/dummyticketforvisa.png"}
        ></img>
        <br />
        <FaqText
          title={
            "Are there specific guidelines provided by embassies or consulates regarding the use of flight reservations in visa applications?"
          }
          desc={
            "Many embassies or consulates have guidelines specifying the acceptability of flight reservations, often requiring genuine and verifiable bookings."
          }
        />
        <FaqText
          title={
            "What advice can be given to ensure compliance with visa application requirements without resorting to fake documentation?"
          }
          desc={
            "To comply with visa requirements, submit legitimate provisional itineraries, verifiable reservations, or consult with travel agencies or airlines for assistance."
          }
        />
        <FaqText
          title={
            "In what ways does the use of fake flight tickets negatively impact one's credibility in a visa application?"
          }
          desc={
            "Using fake flight tickets undermines credibility, potentially leading to visa rejection, bans, and significant challenges in future visa applications due to a lack of trust."
          }
        />
        <br />
      </ul>
      <FaqTextSchengenVisa />

      {/* What are the risks of using fake flight tickets for a visa application?
Could using a fake flight ticket lead to visa rejection or denial?
Are there legal consequences for presenting fake documentation in a visa application?
How can using fake flight tickets impact future travel opportunities or visa applications?
Is there a difference between using a provisional itinerary and a fake flight ticket in a visa application?
Can immigration authorities detect fake flight tickets during the visa application process?
What alternatives exist to fake flight tickets for providing a confirmed travel itinerary in a visa application?
Are there specific guidelines provided by embassies or consulates regarding the use of flight reservations in visa applications?
What advice can be given to ensure compliance with visa application requirements without resorting to fake documentation?
In what ways does the use of fake flight tickets negatively impact one's credibility in a visa application? */}

      <br />
      <br />
      <StickyFooter />
    </div>
  );
}

// how to buy dummy ticket
// cheap dummy ticket
// fake return ticket for visa

export default FaqPage;
