import React from "react";
import Card from "react-bootstrap/Card";
import QuizIcon from "@mui/icons-material/Quiz";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import AirplaneTicketIcon from "@mui/icons-material/AirplaneTicket";
import HelpIcon from "@mui/icons-material/Help";
import VerifiedIcon from "@mui/icons-material/Verified";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import StickyFooter from "./components/bottom.tsx";
// import YoutubeComponent from "./youtube.tsx";

import { lazy } from "react";

// const YoutubeComponent = lazy(() => import("./youtube.tsx"));

function Faq() {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/");
  };
  return (
    <div
      style={
        {
          // height: "100%",
          // margin
          // backgroundImage:
          // "url('https://images.unsplash.com/photo-1513002749550-c59d786b8e6c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2848&q=80')",
        }
      }
    >
      <Box
        sx={{
          // margin: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
      width: "98%",

          backgroundColor: "#eafdff",
          opacity: "0.9",
        }}
      >
        <Card
          style={{
            // margin: "2%",
            // width: "55%",
            // border: "border-dark",
            backgroundColor: "#eafdff",
            padding: "1%",

            // minWidth: "18rem",
            // marginRight: "5%",
            fontSize: "17px",
            opacity: "1",
            // backgroundColor: "#fadfd2",
          }}
        >
          <Card.Body>
            <h1 style={{ fontSize: "25px" }}>
              <QuizIcon fontSize="large" /> FAQs on Dummy Ticket{" "}
            </h1>
            <br />

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>
                  {" "}
                  <Card.Title>
                    <b>
                      {" "}
                      <HelpIcon /> Why use our platform ?{" "}
                    </b>
                  </Card.Title>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <Card.Text>
                    {" "}
                    - <b> Book now. Pay later.</b> Pay full price ($8)after
                    receiving ticket.
                    <br />- <b> Verified ticket for visa. </b>
                    Our reservations are 100% legitimate with a PNR code under
                    your name that you can verify with the airline. <br />-{" "}
                    <b> Free date revision </b> should you change your plan{" "}
                    <br />- <b> Multi city </b> same price for one way, return
                    and multi city.
                    <br />
                  </Card.Text>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>
                  {" "}
                  <Card.Title>
                    <b>
                      {" "}
                      <AirplaneTicketIcon /> What is advance ticket ?{" "}
                    </b>
                  </Card.Title>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <Card.Text>
                    - It is a <b> flight reservation </b> that is serves as a
                    valid reservation for visa application. <br /> - This
                    alternative method is widely used for visa application due
                    to its efficiency and money savings.
                  </Card.Text>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>
                  {" "}
                  <Card.Title>
                    <b>
                      {" "}
                      <VerifiedIcon /> What will you get ?{" "}
                    </b>
                  </Card.Title>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <Card.Text>
                    - An advance ticket in PDF format with a PNR number under
                    your name that you can use to verify on airline’s websites.{" "}
                    <br /> - Your ticket is valid for 48+ hours. Our ticket is
                    valid for a visa application and serves as proof of travel
                    or return ticket.
                  </Card.Text>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>
                  {" "}
                  <Card.Title>
                    <b>
                      {" "}
                      <VerifiedIcon /> Is dummy flight ticket legal?{" "}
                    </b>
                  </Card.Title>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <Card.Text>
                    - Dummy Tickets are completely legal in order to obtain a
                    apply for a visa application. <br />
                    - Here dummy ticket is referred to a actual flight
                    reservation which means flight is on hold just payment is
                    pending. <br />
                    {/* - In order to avoid visa cancellation you must always
              ensure to get a genuine advance ticket which comes with a live
              verifiable PNR. */}
                  </Card.Text>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <br />
          </Card.Body>
          {/* <Suspense fallback={<div>Loading...</div>}>
            <YoutubeComponent />
          </Suspense> */}

          {/* <Button
            // type="submit"

            variant="contained"
            onClick={handleClick}
            sx={{ mt: 3, mb: 1, width: "60%", alignSelf: "center" }}
          >
            {" "}
            Fill Now !
          </Button> */}

        </Card>
        {/* <br /> */}
      </Box>

    </div>
  );
}

export default Faq;
