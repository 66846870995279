import React from "react";
import NavComponent from "./navbar.tsx";
import Card from "react-bootstrap/Card";

import Box from "@mui/material/Box";
import CelebrationIcon from "@mui/icons-material/Celebration";
import MailIcon from "@mui/icons-material/Mail";
import PhoneIcon from "@mui/icons-material/Phone";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import StickyFooter from "./components/bottom.tsx";

function Thanks() {
  return (
    <div
      style={{
        height: "90%",
        backgroundColor: "#aaedff",
        // backgroundImage:
          // "url('https://images.unsplash.com/photo-1513002749550-c59d786b8e6c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2848&q=80')",
      }}
    >
      <NavComponent />
      <br />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          marginLeft : "1%",
          // height: "50vh",
          width: "98%",
          opacity: "0.9",
          fontSize: "20px",
        }}
      >
        <ul class="list-group list-group-flush">
          <Card.Img
            variant="top"
            //   src="https://static.vecteezy.com/system/resources/previews/008/149/282/large_2x/thank-you-papercut-sky-landscape-banner-with-hot-air-balloon-could-and-birds-for-business-marketing-flyers-banners-presentations-and-posters-illustration-vector.jpg"
            src="https://img.freepik.com/free-vector/thank-you-lettering_1262-6963.jpg?w=2000&t=st=1696867076~exp=1696867676~hmac=e561b8561a732b7e0bbc242a5d79adc4fdd5542a348bad6492a1e1a85737164a"
          />
          <li class="list-group-item">
            {" "}
            <b>
              {" "}
              Thanks for Booking! <CelebrationIcon />{" "}
            </b>{" "}
            <br />
            <br /> <SupportAgentIcon /> We will reach out to you soon with all
            details.
          </li>
          <li class="list-group-item">
            {" "}
            <PhoneIcon /> +91-7071341203
          </li>
          <li class="list-group-item">
            {" "}
            <MailIcon /> rakeshranjan.iiita@gmail.com
          </li>
        </ul>

        {/* <p> Scan QR to Pay INR 800 </p> */}
        {/* <img src="https://dummyflights.com/dummy/assets/images/layesrs.png"  class="image_part"  alt="logo2"></img> */}
      </Box>
      <br />
      <br />
      <br />
      <br />

      <br />
      <br />
      <br />
      <br />

      <br />
      <br />
      <br />
      <br />
      <br />
      <StickyFooter/>

      {/* <p> Scan QR to Pay INR 800 </p> */}
      {/* <img src="https://dummyflights.com/dummy/assets/images/layesrs.png"  class="image_part"  alt="logo2"></img> */}
    </div>
  );
}

export default Thanks;
