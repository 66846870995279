import React from "react";
import NavComponent from "./navbar.tsx";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import AirplaneTicketIcon from "@mui/icons-material/AirplaneTicket";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";

function HowPage() {
  const handleClick = () => {
    console.log("Confirming payment");
    //   navigate("/final");
  };
  return (
    <div
      style={{
        height: "100%",
        backgroundImage:
          "url('https://images.unsplash.com/photo-1513002749550-c59d786b8e6c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2848&q=80')",
      }}
    >
      <NavComponent />
      <br />
      {/* <h3>   What You Get</h3> <br/>
 <h4> - An Onward Ticket in PDF format with a PNR number under your name that you can use to verify on airline’s websites. Your ticket is valid for 48+ hours.
 </h4> */}
      {/* <img 
 style={{
    width: "95%",
    marginLeft: "3%",
    minWidth:'18rem' ,
    minHeight:'12rem' ,
  }}
src={process.env.PUBLIC_URL + "/how.png"} alt="" /> */}
      <Box
        sx={{
          margin: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <FlightTakeoffIcon fontSize="large" />
        <Typography component="h1" variant="h3">
          How It Works
        </Typography>
        <ul
          class="list-group list-group-flush"
          style={{
            backgroundColor: "#eafdff",
            width: "80%",
            margin: "5%",
            opacity: "0.9",
            padding: 4,
            // fontFamily: "Poppins",
            fontSize: "20px",
          }}
        >
          <li class="list-group-item">
            {" "}
            <BorderColorIcon></BorderColorIcon> <b> Fill Up The Form </b> <br />
            <br />
            - Provide us with your complete details, your name, desired date of
            travel, your email, departure and arrival airports. <br />- All your
            data are encrypted and protected..
          </li>
          <li class="list-group-item">
            {" "}
            <b>
              {" "}
              <MonetizationOnIcon /> Make The Payment{" "}
            </b>{" "}
            <br /> <br />
            - Once payment has been made, our staff will process the booking
            with your provided information. <br />- 100% secured payment with
            stripe/upi.
          </li>
          <li class="list-group-item">
            {" "}
            <b>
              {" "}
              <AirplaneTicketIcon /> Get Advance Ticket{" "}
            </b>{" "}
            <br /> <br />- Your Advance Ticket will be sent to you as a PDF
            attachment via email within 5 - 60 minutes.
            <br /> - You can{" "}
            <b> verify your dummy ticket on airline websites </b>.
            <a href="https://advance-ticket.com/faq" >   Read more on dummy tickets </a> .
          </li>
        </ul>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />

        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />

        {/* <p> Scan QR to Pay INR 800 </p> */}
        {/* <img src="https://dummyflights.com/dummy/assets/images/layesrs.png"  class="image_part"  alt="logo2"></img> */}
      </Box>
    </div>
  );
}

export default HowPage;
