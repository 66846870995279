import React from "react";
import NavComponent from "./navbar.tsx";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import InfoIcon from "@mui/icons-material/Info";
import Box from "@mui/material/Box";
import MailIcon from "@mui/icons-material/Mail";
import PhoneIcon from "@mui/icons-material/Phone";
import InsertEmoticonIcon from "@mui/icons-material/InsertEmoticon";
import LanguageIcon from "@mui/icons-material/Language";
import BoltIcon from "@mui/icons-material/Bolt";
import AssistantIcon from "@mui/icons-material/Assistant";
import BlindIcon from "@mui/icons-material/Blind";

function Contact() {
  const handleClick = () => {
    console.log("Confirming payment");
    //   navigate("/final");
  };
  return (
    <div
      style={{
        height: "100%",
        backgroundImage:
          "url('https://images.unsplash.com/photo-1513002749550-c59d786b8e6c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2848&q=80')",
      }}
    >
      <NavComponent />
      <Container>
        <Box
          sx={{
            marginTop: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <br />
          <br />
          <br />

          <Row className="justify-content-md-center" mx={8}>
            <Col>
              <Card
                style={{
                  margin: "2%",
                }}
              >
                <Card.Body>
                  <Card.Title>
                    {" "}
                    <h2>
                      {" "}
                      <InfoIcon fontSize="large"> </InfoIcon> About us{" "}
                    </h2>
                  </Card.Title>
                  <br />
                  <Card.Text>
                    <BoltIcon /> We aim to make the process of meeting your visa
                    requirements simpler, faster and affordable.
                    <br />
                    <br />
                    <BlindIcon /> We assist with recognized flight and hotel
                    reservations for the application of a visa when planning
                    international travel.
                    <br />
                    <br />
                    <AssistantIcon /> Most embassy encourage travelers against
                    the purchase of full airline tickets or securing
                    accommodation before their visa is approved. This is to
                    minimize your lost in the case if the embassy refuse your
                    application.
                    <br />
                    <br />
                    <br />
                    <ul class="list-group list-group-flush">
                      <li class="list-group-item">
                        {" "}
                        <PhoneIcon /> +91-7071341203
                      </li>
                      <li class="list-group-item">
                        {" "}
                        <MailIcon /> rakeshranjan.iiita@gmail.com
                      </li>
                      <li class="list-group-item">
                        {" "}
                        <LanguageIcon /> Website:{" "}
                        <a href="https://gackpacker.com/">
                          https://gackpacker.com/
                        </a>
                      </li>
                    </ul>
                  </Card.Text>
                </Card.Body>
                <br />
              </Card>
              <br />
            </Col>
            <Col xs lg="2"></Col>
          </Row>
        </Box>
      </Container>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br /> <br />
      <br />
      <br /> <br />
      <br />
      <br />
      <br />
      {/* <p> Scan QR to Pay INR 800 </p> */}
      {/* <img src="https://dummyflights.com/dummy/assets/images/layesrs.png"  class="image_part"  alt="logo2"></img> */}
    </div>
  );
}

export default Contact;
