// import "./App.css";
import NavComponent from "./navbar.tsx";
import BasicExampleForm from "./components/form.tsx";
import StickyFooter from "./components/bottom.tsx";
import How from "./components/how.tsx";
import HomeForm from "./components/homeForm.tsx";

import Button from "react-bootstrap/Button";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { UserContext } from "./App";
// import Alert from "react-bootstrap/Alert";
import Alert from "@mui/material/Alert";

import { useState } from "react";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase, ref, set } from "firebase/database";
import Faq from "./components/faq.tsx";

// TODO: Replace the following with your app's Firebase project configuration
// See: https://firebase.google.com/docs/web/learn-more#config-object

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCFzf91zwa4hFdSgpGpGZIa1Tit24ZQkwo",
  authDomain: "advance-ticket.firebaseapp.com",
  projectId: "advance-ticket",
  storageBucket: "advance-ticket.appspot.com",
  messagingSenderId: "327180941839",
  appId: "1:327180941839:web:4f0961c6d26e8ca641817a",
  measurementId: "G-QX5Z777LZG",
  databaseURL: "https://advance-ticket-default-rtdb.firebaseio.com",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

// // Initialize Realtime Database and get a reference to the service
const database = getDatabase(app);

function Home() {
  const navigate = useNavigate();
  const { user, setUser } = useContext(UserContext);
  const [showAlert, setShowAlert] = useState(false);

  function writeUserData() {
    const db = getDatabase();
    set(ref(db, "users/" + user.firstName), {
      firstName: user.firstName,
      // lastName: user.lastName,
      title: user.title,
      fromCity: user.fromCity,
      destCity: user.destCity,
      date: user.date,
      email: user.email,
      nationality: "",
      passportIssuingCountry: "",
      passportNo: "",
      passportExpiry: "",
      dob: "",
      mobileNumber: user.mobileNumber,
      // profile_picture : imageUrl
    });
  }

  const handleClick = () => {
    console.log(user);
    writeUserData();
    if (user["email"] === "" || user["date"] === "" || !user["email"].includes('@') || user['mobileNumber'].length < 6) {
      // alert('please fill details!');
      setShowAlert(true);
      // setShowAlert({visible:true},()=>{
      //   window.setTimeout(()=>{
      //     setShowAlert({visible:false})
      //   },2000)
      // });
      setShowAlert(true);
      setTimeout(() => {
        /// ---> 2 seconds later which is closing
        setShowAlert(false);
      }, 2000);
      return;
    }
    window.scrollTo(0, 0);
    navigate("/payment");
  };

  return (
    <div>
      <NavComponent />
      {/* <h1> Dummy ticket for visa</h1> */}
      <section
        style={{
          height: "100%",
          // backgroundImage: "url('/back.jpeg')",
          // backgroundImage: "url()",
          // backgroundColor: "#aafdff",
          //
          backgroundColor: "#aaedff",
          // backgroundImage:
          //   "url('https://images.unsplash.com/photo-1513002749550-c59d786b8e6c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2848&q=80')",
        }}
      >
        <header className="App-header">
          {/* <Fade in={true}> { <b> ABCD</b>}  </Fade>  */}
          <HomeForm />
          {/* <img
                      src={process.env.PUBLIC_URL + "/canva-final.png"}
                      width={"55%"}
                      height={"100%"}
                      min-height={"10rem"}
                      class="image_part"
                      alt="logo2"
                    ></img> */}
          <br />
          <Faq />
          <br />
          <BasicExampleForm />
          {showAlert && (
            // <Alert key="warning" variant="warning">
            //   <h4>Please fill all details! </h4>
            // </Alert>
            <Alert severity="warning">Please fill all details!</Alert>
          )}
          <br />
          <Button
            style={{ width: "27%", minWidth: "12rem" }}
            onClick={handleClick}
            // onTouchMove={}
            variant="info"
            size="lg"
            type="submit"
          >
            Book Now
          </Button>{" "}
          <br />
          <How />
          <br />
          {/* <img
            src="https://dummyflights.com/dummy/assets/images/layesrs.png"
            class="image_part"
            alt="logo2"
          ></img> */}
          <br />
        </header>
      </section>
      <StickyFooter />
    </div>
  );
}

export default Home;
