import React from "react";
import NavComponent from "./navbar.tsx";
import StickyFooter from "./components/bottom.tsx";
import FaqTextSchengenVisa from "./components/faqSchengenVisa.tsx";

function FaqSchengenPage() {
  return (
    <div
      style={{
        height: "100%",
        backgroundColor: "#aaedff",
      }}
    >
      <NavComponent />
      <br />
     
      <FaqTextSchengenVisa />

      <br />
      <br />
      <StickyFooter />
    </div>
  );
}

// how to buy dummy ticket
// cheap dummy ticket
// fake return ticket for visa

export default FaqSchengenPage;
