import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import AirplaneTicketIcon from "@mui/icons-material/AirplaneTicket";

function How() {
  return (
    <Box
      sx={{
        margin: "2%",
        display: "flex",
        padding: "2%",
        flexDirection: "column",
        alignItems: "center",
        color: "black",
        width: "100%",
      }}
    >
      <ul
        class="list-group list-group-flush"
        style={{
          backgroundColor: "#eafdff",
          opacity: "0.9",
          padding: "3%",
          margin: "2%",
          // margin:"2%",
          fontSize: "17px",
        }}
      >
        <Typography
          component="h3"
          variant="h4"
          color={"black"}
          fontSize={"25px"}
        >
          {" "}
          How it works 
          {/* <HelpIcon fontSize="large" /> */}
        </Typography>
        <li class="list-group-item" style={{ backgroundColor: "#eafdff" }}>
          {" "}
          <BorderColorIcon></BorderColorIcon> <b> Fill Up The Form </b> <br />
          - Provide us with your complete details, your name, desired date of
          travel etc... <br />
        </li>
        <li class="list-group-item" style={{ backgroundColor: "#eafdff" }}>
          {" "}
          <b>
            {" "}
            <MonetizationOnIcon /> Make The $1 Payment{" "}
          </b>{" "}
          <br />- Once payment has been made, our staff will process the booking
          with provided information.
        </li>
        <li class="list-group-item" style={{ backgroundColor: "#eafdff" }}>
          {" "}
          <b>
            {" "}
            <AirplaneTicketIcon /> Get Advance Ticket{" "}
          </b>{" "}
          <br />- Your Advance Ticket will be sent to you as a PDF attachment
          via email within 5 - 60 minutes.
          <br /> - You can <b> verify your dummy ticket on airline websites </b>
          .{/* Read{" "} */}
          {/* <a href="/faq"> more </a> . */}
        </li>
        <li class="list-group-item" style={{ backgroundColor: "#eafdff" }}>
          {" "}
          <b>
            {" "}
            <MonetizationOnIcon /> Pay the remaining amount{" "}
          </b>{" "}
          <br />- Once you verify your ticket, make the remaining payment of $7.
        </li>
      </ul>
    </Box>
  );
}

export default How;
